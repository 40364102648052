export default defineNuxtPlugin((app) => {
  const initTime = setTimeout(() => {
    const script = document.createElement("script");
    script.innerHTML =
      'function loadScript(a){var b=document.getElementsByTagName("head")[0],c=document.createElement("script");c.type="text/javascript",c.src="https://tracker.metricool.com/resources/be.js",c.onreadystatechange=a,c.onload=a,b.appendChild(c)}loadScript(function(){beTracker.t({hash:"28f4c7ac46b6b089fc70482ba2d86383"})});';
    script.async = true;
    document.getElementsByTagName("head")[0].appendChild(script);
    clearTimeout(initTime);
  }, 5000);
});
